import React from 'react'
import Header from '../../components/Header'
import "./style.css";


function ThankYou() {
  return (
    <>
      <Header/>
    
 <div className="thankyou">
      <h1>Thank You!</h1>
      <p style={{fontSize:"20px"}}>
        <strong>Please check and verify your email</strong> to access the
        Application Forms. We have received your enquiry and will respond to you
        within 24 hours. For urgent enquiries, please call us at one of the
        telephone numbers below.
      </p>
      <button className="btn" onClick={() => (window.location.href = "/")}>Continue to homepage</button>
    </div>
    </>
  )
}

export default ThankYou